<script>
import { siteService } from "../../../helpers/backend/site.service"
import { machineService } from "../../../helpers/backend/machine.service"
import "dual-listbox-vue/dist/dual-listbox.css"
import { routeService } from "../../../helpers/backend/route.service"
export default {
  components: {},
  data() {
    return {
      form: {
        id: null,
        name: "",
      },
      activityData: [],
      busy: false,
      show: true,
      showDismissibleAlert: false,
      route_target: null,
      route_target_type: [
        { text: "Select", value: null },
        { text: "Machine", value: "machine" },
        { text: "Site", value: "site" },
      ],
      error: "",
      active: false,
      source: [],
      destination: [],
      target_options: [],
      target_options_data: [],
      target_id: null,
      showAlert: false,
    }
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  mounted() {
    this.reset()
  },
  methods: {
    onChangeList: function ({ source, destination }) {
      this.source = source
      this.destination = destination
    },

    onSubmit(event) {
      event.preventDefault()
      if (this.activityData.length < 1) {
        this.showAlert = true
        return
      }
      this.busy = true
      this.form.destination = this.activityData
      routeService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error = "Failed to Insert/Update Route. Error : " + error
          this.busy = false
        }
      )
    },
    setPosition(position) {
      this.form.coordinate = position
    },
    reset() {
      // Reset our form values
      this.form.id = null
      this.form.name = ""
      this.activityData = []
      this.form.destination = []
      this.showAlert = false
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    changeRouteTargetOptions() {
      this.target_id = null
      this.target_options = []
      switch (this.route_target) {
        case "machine":
          machineService.getAllMachine().then(
            (data) => {
              if (data.machines) {
                this.target_options = data.machines.map(function (m) {
                  return { text: m.name, value: m.id }
                })
                for (let index = 0;index < data.machines.length;index++) {
                  const element = data.machines[index]
                  this.target_options_data[element.id] = {
                    name: element.name,
                    desc: element.location,
                  }
                }
              }
              //alert(typeof(this.machine_types))
            },
            (err) => {
              this.showDismissibleAlert = true
              this.error = "Failed to get machine. Error : " + err
            }
          )
          break
        case "site":
          siteService.getAll().then(
            (data) => {
              if (data.sites) {
                this.target_options = data.sites.map(function (m) {
                  return { text: m.name, value: m.id }
                })
                for (let index = 0;index < data.sites.length;index++) {
                  const element = data.sites[index]
                  this.target_options_data[element.id] = {
                    name: element.name,
                    desc: element.address,
                  }
                }
              }
              //alert(typeof(this.machine_types))
            },
            (err) => {
              this.showDismissibleAlert = true
              this.error = "Failed to get site. Error : " + err
            }
          )
          break
      }
    },
    addItem() {
      if ((this.target_id != null) & (this.route_target_type != null)) {
        this.activityData.push({
          type: this.route_target,
          name: this.target_options_data[this.target_id].name,
          desc: this.target_options_data[this.target_id].desc,
          target_id: this.target_id,
        })
        this.target_id = null
      }else{
        this.showDismissibleAlert = true
        this.error = "Failed to add new route. Error : Choose route target and target options first." 
      }
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    array_move(arr, fromIndex, toIndex) {
      var element = arr[fromIndex]
      arr.splice(fromIndex, 1)
      arr.splice(toIndex, 0, element)
      return arr
    },
    moveUp(index) {
      if (index <= 0) return
      this.activityData = this.array_move(this.activityData, index, index - 1)
    },
    moveDown(index) {
      if (index >= this.activityData.length - 1) return
      this.activityData = this.array_move(this.activityData, index, index + 1)
    },
    removeItem(index) {
      if (index > -1 && index <= this.activityData.length - 1) {
        this.activityData.splice(index, 1)
      }
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.name.$el.focus()
        this.busy = true

        routeService.getById(id).then(
          (data) => {
            if (data.route != null) {
              this.form.id = data.route.id
              this.form.name = data.route.name
              this.activityData = data.route.destination.map((d) => {
                if (d.machine.id > 0) {
                  return {
                    target_id: d.machine.id,
                    name: d.machine.name,
                    desc: d.machine.location,
                    type: "machine",
                  }
                } else {
                  return {
                    target_id: d.site.id,
                    name: d.site.name,
                    desc: d.site.address,
                    type: "site",
                  }
                }
              })
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get route type to update. Error : Route not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get route to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
  },
}
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Route Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Route Name"
                  required
                  ref="name"
                ></b-form-input>
              </b-form-group>
              <div class="row">
                <b-form-group
                  id="input-group-2"
                  label="Route target:"
                  class="col-lg-6 col-sm-12"
                  label-for="input-3"
                >
                  <b-form-select
                    id="input-2"
                    :options="route_target_type"
                    v-model="route_target"
                    @change="changeRouteTargetOptions"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  class="col-lg-6 col-sm-12"
                  label="Target options:"
                  label-for="input-3"
                >
                  <b-form-select
                    id="input-3"
                    v-model="target_id"
                    :options="target_options"
                  ></b-form-select>
                </b-form-group>
                <b-button class="col-sm-12" @click="addItem">Add</b-button>
              </div>

              <br />
              <div>
                <b-alert variant="warning" :show="showAlert"
                  >You need to add at least 1 target!</b-alert
                >
              </div>
              <div data-simplebar style="max-height: 330px">
                <ul class="list-unstyled activity-wid">
                  <li
                    class="
                      activity-list
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    v-for="(data, index) in this.activityData"
                    :key="index"
                  >
                    <div>
                      <div class="activity-icon avatar-xs">
                        <span
                          class="
                            avatar-title
                            bg-soft-primary
                            text-primary
                            rounded-circle
                          "
                        >
                          <i
                            class="ri-map-pin-line"
                            v-if="data.type == 'site'"
                          />
                          <i class="ri-database-line" v-else />
                        </span>
                      </div>
                      <div>
                        <div>
                          <h5 class="font-size-13">
                            {{ data.name }}
                            <div class="badge font-size-12 badge-success">
                              <small class="text">{{
                                data.type.toUpperCase()
                              }}</small>
                            </div>
                          </h5>
                        </div>

                        <div>
                          <p class="text-muted mb-0">{{ data.desc }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <button
                          type="button"
                          class="btn btn-light"
                          @click="moveUp(index)"
                        >
                          <i class="ri-arrow-up-fill"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-light"
                          @click="moveDown(index)"
                        >
                          <i class="ri-arrow-down-fill"></i>
                        </button>
                      </div>
                      <div class="col-6">
                        <button
                          type="button"
                          class="btn btn-danger"
                          @click="removeItem(index)"
                        >
                          <i class="ri-close-circle-fill"></i>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <br />
              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div>
</template>



