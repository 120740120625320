import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const routeService = {
    getAll,
    createOrUpdate,
    getById,
    deleteById,
};

function getById(id) {
    const requestOptions = {
        url : '/api/v1/user/route/' + id,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        url : '/api/v1/user/route',
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance( requestOptions)
}


function createOrUpdate(jsonData) {

    // let formData = new FormData();

    // for ( var key in jsonData ) {
    //     if (jsonData[key] != null)
    //         formData.append(key, jsonData[key]);
    // }
    // let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    // const requestOptions = {
    //     url : '/api/v1/user/site',
    //     method: rMethod,
    //     data: formData,
    //     headers: authHeader()
    // };

     
    let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    const requestOptions = {
        url : `/api/v1/user/route`,
        method: rMethod,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(jsonData)
    };
    return axiosInstance(requestOptions);
}


function deleteById(id) {
    const requestOptions = {
        url: `/api/v1/user/route/` + id,
        method: 'DELETE',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}

